<template>
  <div class="header">
    <div class="wrap">
      <div class="title flex-row flex-center">
        <router-link to="/" class="home">
          <img :src="logo" class="logo" />
          <span class="title-text"> 中医临床药学服务<br>与调剂教育平台 </span>
        </router-link>
        <ul class="nav-menu flex-row justify-evenly flex1">
          <li
            v-for="(item, index) in links"
            :key="index"
            :class="{ active: item.active }"
          >
            <router-link
              :to="item.url"
              :class="{ active: item.url === '/download' }"
              >{{ item.name }}</router-link
            >
          </li>
        </ul>
        <router-link to="/download" class="app-download">下载APP</router-link>
      </div>
    </div>
  </div>
  <div :id="id" style="padding: 1px 0">
    <div class="wrap">
      <div class="download flex-row" style="line-height: 1.6">
        <div class="flex1 flex-column flex-center" style="margin: 2rem">
          <img :src="use_app" style="width: 100%" />
        </div>
        <div class="flex1" style="margin: 2rem">
          <div>扫描下方二维码</div>
          <h3>药事服务在线平台APP下载</h3>
          <div
            class="qrcode"
            :qrcode="url_download"
            style="
              width: 15rem;
              height: 15rem;
              color: white;
              margin: 1rem 0;
              box-shadow: 0 0 5px #888888;
            "
          ></div>
          <!-- <div class="flex-row">
            <div class="brand-btn">
              <span class="picon pi-apple-fill"></span>
              Appstore下载
            </div>
            <div class="brand-btn" style="background-color: #94c231">
              <span class="picon pi-android"></span>
              Android下载
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script >
import { Options, Vue } from "vue-class-component";
import Swiper from "swiper";
import "swiper/css/swiper.min.css";
import newid from "@/utils/key";
const logo = require("../assets/logo.jpg");
const use_app = require("../assets/use-app.png");

@Options({
  components: {},
})
export default class Home extends Vue {
  logo = logo;
  use_app = use_app;
  url_download = encodeURIComponent(location.origin + "/app.html");
  id = newid();
  mounted() {
    // make qrcode
    var qr_items = document.querySelectorAll(`#${this.id} .qrcode`);
    var len = qr_items.length,
      i,
      el,
      code;
    for (i = 0; i < len; i += 1) {
      el = qr_items[i];
      if (el && (code = el.getAttribute("qrcode"))) {
        code = decodeURIComponent(code);
        if (code) {
          var w = el.clientWidth || 64;
          try {
            new QRCode(el, {
              text: code,
              width: w,
              height: w,
            });
          } catch (e) {
            console.error(e);
          }
        }
      }
    }
  }
}
</script>

<style scoped lang="less">
.download {
  margin: 8rem;
  background-color: white;
  padding: 8rem 1rem;
}
.brand-btn {
  line-height: 1.8;
  height: 1.8em;
  border-radius: 0.9em;
  padding: 0 0.7em;
  background-color: black;
  color: white;
  margin-right: 1em;
}
</style>